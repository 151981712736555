import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  loginAPI,
  logoutAPI,
  signUpAPI,
  signInWithGoogleAPI,
} from "../../services/AuthService";

export const login = createAsyncThunk(
  "user/login",
  async ({ email, password }, { dispatch, rejectWithValue }) => {
    try {
      const userAuth = await loginAPI(email, password);
      return userAuth;
    } catch (error) {
      // Use `rejectWithValue` to return a custom error payload
      return rejectWithValue(error.message);
    }
  }
);

export const googleSignIn = createAsyncThunk(
  "user/googleSignIn",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const userAuth = await signInWithGoogleAPI();
      dispatch(
        login({
          email: userAuth.user.email,
          uid: userAuth.user.uid,
          displayName: userAuth.user.displayName,
          photoUrl: userAuth.user.photoURL,
        })
      );
      return userAuth;
    } catch (error) {
      // Use `rejectWithValue` to return a custom error payload
      return rejectWithValue(error.message);
    }
  }
);

export const signUp = createAsyncThunk(
  "user/signUp",
  async ({ email, password, displayName }, { dispatch, rejectWithValue }) => {
    try {
      const userAuth = await signUpAPI(email, password, displayName);
      return userAuth;
    } catch (error) {
      // Use `rejectWithValue` to return a custom error payload
      return rejectWithValue(error.message);
    }
  }
);

export const logout = createAsyncThunk(
  "user/logout",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await logoutAPI();
      return null;
    } catch (error) {
      // Use `rejectWithValue` to return a custom error payload
      return rejectWithValue(error.message);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    loading: false,
    error: null,
  },
  reducers: {
    updateProfile: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(googleSignIn.pending, (state) => {
        // When the sign-in process is ongoing
        state.loading = true;
        state.error = null;
      })
      .addCase(googleSignIn.fulfilled, (state, action) => {
        // When the sign-in process is successful
        state.loading = false;
        state.user = action.payload.user;
      })
      .addCase(googleSignIn.rejected, (state, action) => {
        // When the sign-in process fails
        state.loading = false;
        state.error = action.payload; // Assuming you're using rejectWithValue for error handling
      })
      .addCase(login.pending, (state) => {
        // When the sign-in process is ongoing
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        // When the sign-in process is successful
        state.loading = false;
        state.user = action.payload.user;
      })
      .addCase(login.rejected, (state, action) => {
        // When the sign-in process fails
        state.loading = false;
        state.error = action.payload; // Assuming you're using rejectWithValue for error handling
      })
      .addCase(signUp.pending, (state) => {
        // When the sign-in process is ongoing
        state.loading = true;
        state.error = null;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        // When the sign-in process is successful
        state.loading = false;
        state.user = action.payload.user;
      })
      .addCase(signUp.rejected, (state, action) => {
        // When the sign-in process fails
        state.loading = false;
        state.error = action.payload; // Assuming you're using rejectWithValue for error handling
      })
      .addCase(logout.pending, (state) => {
        // When the sign-in process is ongoing
        state.loading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state, action) => {
        // When the sign-in process is successful
        state.loading = false;
        state.user = null;
      })
      .addCase(logout.rejected, (state, action) => {
        // When the sign-in process fails
        state.loading = false;
        state.error = action.payload; // Assuming you're using rejectWithValue for error handling
      });
  },
});

export const { updateProfile, setUser } = userSlice.actions;

export const selectUser = (state) => state.user.user;

export default userSlice.reducer;
