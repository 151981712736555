import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { APP_NAME } from "../utils/constants";

function Footer() {
  return (
    <AppBar position="static" color="primary" sx={{ top: "auto", bottom: 0 }}>
      <Container maxWidth="md">
        <Toolbar>
          <Typography
            variant="body1"
            color="inherit"
            align="center"
            sx={{ flexGrow: 1 }}
          >
            © {new Date().getFullYear()} {APP_NAME}
            {/* <Link
              href="https://heltoncompanies.com"
              target="_blank"
              color="inherit"
            >
              Helton Company
            </Link> */}
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Footer;
