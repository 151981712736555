import React, { lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser, selectUser } from "./features/user/userSlice";
import { auth, onAuthStateChanged } from "./services/firebase";
import Header from "./components/Header";
import Footer from "./components/Footer";
import useAuthListener from "./features/user/useAuthListener";

const Login = lazy(() => import("./screens/Login"));
const Signup = lazy(() => import("./screens/Signup"));
const Dashboard = lazy(() => import("./screens/Dashboard"));
const NewList = lazy(() => import("./screens/NewList"));
const ListDetails = lazy(() => import("./screens/ListDetails"));
const Error404 = lazy(() => import("./screens/Error404"));

function App() {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  useAuthListener();

  useEffect(() => {
    onAuthStateChanged(auth, (userAuth) => {
      if (userAuth) {
        dispatch(
          setUser({
            email: userAuth.email,
            uid: userAuth.uid,
            displayName: userAuth.displayName,
            photoUrl: userAuth.photoURL,
          })
        );
      }
    });
  }, []);

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          {user ? <Header /> : <p>Wish Whisperer</p>}
        </header>
        <nav>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              {/* Redirect already authenticated users from login/signup to dashboard */}
              {user && (
                <>
                  <Route
                    path="/login"
                    element={<Navigate to="/dashboard" replace />}
                  />
                  <Route
                    path="/signup"
                    element={<Navigate to="/dashboard" replace />}
                  />
                </>
              )}

              {/* Routes for unauthenticated users */}
              {!user && (
                <>
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="*" element={<Navigate to="/login" replace />} />
                </>
              )}

              {/* Routes for authenticated users */}
              {user && (
                <>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/list/:listId" element={<ListDetails />} />
                  <Route path="/new-list" element={<NewList />} />
                  <Route path="/404" element={<Error404 />} />

                  {/* Fallback for authenticated users */}
                  <Route
                    path="*"
                    element={<Navigate to="/dashboard" replace />}
                  />
                </>
              )}
            </Routes>
          </Suspense>
        </nav>
        <footer>
          <Footer />
        </footer>
      </div>
    </Router>
  );
}

export default App;
