import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "./userSlice";
import { auth, onAuthStateChanged } from "../../services/firebase";

const useAuthListener = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // This listener subscribes to auth state changes
    const unsubscribe = onAuthStateChanged(auth, (userAuth) => {
      if (userAuth) {
        // Dispatch action to set user data in Redux store
        dispatch(
          setUser({
            email: userAuth.email,
            uid: userAuth.uid,
            displayName: userAuth.displayName,
            photoUrl: userAuth.photoURL,
          })
        );
      } else {
        // Dispatch action to reset the user state
        dispatch(setUser(null));
      }
    });

    // Cleanup function to unsubscribe from the listener
    return () => unsubscribe();
  }, [dispatch]);

  // This hook doesn't return anything as it's just for setting up a listener
};

export default useAuthListener;
