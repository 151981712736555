import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#007BFF",
    },
    secondary: {
      main: "#FFC107",
    },
    error: {
      main: "#f44336",
    },
    complementary: {
      main: "#DC3545",
    },
    accent: {
      main: "#28A745",
    },
    neutral: {
      main: "6C757D",
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#556cd6", // Customize the link color
          textDecoration: "none", // Removes underline
          "&:hover": {
            textDecoration: "underline", // Adds underline on hover
            color: "#333", // Change color on hover
          },
        },
      },
    },
  },
});

export default theme;
