import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { APP_NAME } from "../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../features/user/userSlice";
import { logout } from "../features/user/userSlice";

function Header() {
  const dispatch = useDispatch();

  const logoutOfApp = () => {
    dispatch(logout());
  };

  const user = useSelector(selectUser);

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {APP_NAME}
        </Typography>
        {user && (
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {user.displayName}
          </Typography>
        )}
        <IconButton color="inherit" onClick={logoutOfApp}>
          Logout
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
