import { db } from "./firebase";
import {
  collection,
  query,
  addDoc,
  getDocs,
  doc,
  getDoc,
  where,
  deleteDoc,
} from "firebase/firestore";

export const getWishListsAPI = async (uid) => {
  try {
    const listsRef = collection(db, "lists");
    const q = query(listsRef, where("ownerUID", "==", uid));
    const querySnapshot = await getDocs(q);
    let lists = [];
    querySnapshot.forEach((doc) => {
      lists.push({ id: doc.id, ...doc.data() });
    });
    return lists;
  } catch (error) {
    console.error("Error fetching wish list by ID: ", error);
  }
  return [];
};

export const findUserByEmailAPI = async (email) => {
  try {
    console.log("findUserByEmailAPI: ", email);
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);
    let users = [];
    querySnapshot.forEach((doc) => {
      users.push({ id: doc.id, ...doc.data() });
    });
    return users[0];
  } catch (error) {
    console.error("Error fetching wish list by ID: ", error);
  }
  return null;
};

export const shareWishListAPI = async (listId, uid) => {
  try {
    const sharedWithRef = collection(db, "lists", listId, "sharedWith");
    await addDoc(sharedWithRef, { uid, sharedAt: new Date() });
  } catch (error) {
    console.error("Error sharing wish list: ", error);
  }
};

export const addItemToListAPI = async (listId, item) => {
  try {
    const itemsRef = collection(db, "lists", listId, "items");
    await addDoc(itemsRef, {
      ...item,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  } catch (error) {
    console.error("Error sharing wish list: ", error);
  }
};

export const removeItemFromListAPI = async (listId, itemId) => {
  try {
    const itemRef = doc(db, "lists", listId, "items", itemId);
    await deleteDoc(itemRef);
  } catch (error) {
    console.error("Error removing item from wish list: ", error);
  }
};

export const updateItemInListAPI = async (listId, itemId, item) => {
  try {
    const itemRef = doc(db, "lists", listId, "items", itemId);
    await itemRef.update({ ...item, updatedAt: new Date() });
  } catch (error) {
    console.error("Error updating item in wish list: ", error);
  }
};

export const getWishListByIDAPI = async (id) => {
  try {
    // Fetch the wish list data
    const docRef = doc(db, "lists", id);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      console.error("No such document!");
      return null;
    }

    const listData = docSnap.data();

    // Fetch items from the 'items' subcollection
    const itemsRef = collection(db, "lists", id, "items");
    const itemsSnapshot = await getDocs(itemsRef);
    const items = itemsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Combine list data and items
    return { ...listData, items };
  } catch (error) {
    console.error("Error fetching wish list by ID: ", error);
  }
};

export const createWishListAPI = async ({ listName, items, ownerUID }) => {
  try {
    const res = await addDoc(collection(db, "lists"), {
      listName,
      createdAt: new Date(),
      updatedAt: new Date(),
      ownerUID,
    });
    for (let item of items) {
      await addItemToListAPI(res.id, item);
    }
    return res.id;
  } catch (error) {
    console.error("Error fetching lists: ", error);
  }
};
