// src/features/wishList/wishListSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getWishListsAPI,
  createWishListAPI,
} from "../../services/FirestoreService";

export const getWishLists = createAsyncThunk(
  "wishList/getWishLists",
  async ({ uid }, { dispatch, rejectWithValue }) => {
    try {
      const lists = await getWishListsAPI(uid);
      return lists;
    } catch (error) {
      // Use `rejectWithValue` to return a custom error payload
      return rejectWithValue(error.message);
    }
  }
);

export const createWishList = createAsyncThunk(
  "wishList/createWishList",
  async ({ listName, items, ownerUID }, { dispatch, rejectWithValue }) => {
    try {
      const newList = await createWishListAPI({
        listName,
        items,
        ownerUID,
      });
      dispatch(getWishLists({ uid: ownerUID }));
      return newList;
    } catch (error) {
      // Use `rejectWithValue` to return a custom error payload
      return rejectWithValue(error.message);
    }
  }
);

export const wishListSlice = createSlice({
  name: "wishList",
  initialState: {
    lists: [],
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWishLists.fulfilled, (state, action) => {
        state.lists = action.payload;
        state.loading = false;
      })
      .addCase(getWishLists.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getWishLists.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createWishList.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createWishList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createWishList.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      });
  },
});

// Action creators are generated for each reducer function
export const {} = wishListSlice.actions;

export const selectLists = (state) => state.wishList.lists;

export default wishListSlice.reducer;
